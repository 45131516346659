<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Button @click="haddleNew">新建</Button>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
      <!-- <FormItem>
        <FormItem>
          <Select v-model="queryForm.district" placeholder="区域过滤">
            <Option :value="''">不限</Option>
            <Option :value="'Auckland City'">中区</Option>
            <Option :value="'Manukau City'">东南区</Option>
            <Option :value="'North Shore City'">北岸</Option>
            <Option :value="'Papakura'">南区</Option>
            <Option :value="'Waitakere City'">西区</Option>
            <Option :value="'Franklin'">西南区</Option>
            <Option :value="'Rodney'">西北区</Option>
          </Select>
        </FormItem>
      </FormItem> -->
      <FormItem>
        <Select v-model="queryForm.businessTypeAlias" placeholder="业态过滤">
          <Option v-for="item in businessTypeInList" :value="item.alias" :key="item.alias">
            {{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="daterange"
          placement="bottom-end"
          placeholder="创建日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="itemList" :loading="tableLoading">
      <template #openUrl="{ row }">
        <span v-if="row.url" @click="openUrl">打开店铺</span>
        <span v-else>-</span>
      </template>
    </Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showArticeList" title="文章列表" @on-ok="() => showArticeList = false" width="65%">
      <ArticeList v-if="showArticeList" :storeAlias="articeAlias" ref="artice" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { disableStore, queryBusinesstype, queryStore, enableStore } from "@/api"
import { toHumanDate } from "@/util"
import ArticeList from "@/components/artice/ArticeList.vue"
import "@/components/tableUtil"

export default {
  name: "StoreQueryPage",
  components: { ContentWrapper, Pager, ArticeList },
  data() {
    return {
      topLinks: [{ title: "店铺列表", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm:  Object.assign({}, {
        keyword: "",
        district: null,
        businessTypeAlias: null,
        startTime: null,
        endTime: null 
      }, this.$route.query),
      page: this.$route.query.page ? Number(this.$route.query.page) : 1,
      tableLoading: false,
      itemList: [],
      itemListCount: 0,
      articeAlias: "",
      businessTypeInList: [],
      columns: [
        { title: "标识", key: "storeAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "名称",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h("div", params.row.name)
          },
          fixed: "left",
        },
        {
          title: "店长",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 150,
          fixed: "left",
        },
        {
          title: "区域",
          width: 350,
          render(h, params) {
            return h("div", params.row.address.fullAddress)
          },
        },
        {
          title: "电商",
          width: 100,
          slot: "openUrl",
          align: "center"
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
          width: 100,
        },
        {
          title: "状态",
          render: (h, params) => {
            return h("div", params.row.status === 1 ? "正常" : "禁用")
          }
        },
        {
          title: "操作",
          width: 259,
          render: (h, params) =>
            h("ButtonGroup", [
              h("Button", {
                props: { type: "primary" },
                on: {
                  click: () => {
                    this.showUserArticeList(params.row.storeAlias)
                  }
                }
              },
              "查看文章"),
              h("Button", {
                props: { type: "success" },
                on: {
                  click: () => {
                    this.$router.push({ name: "StorePublishPage", query: { alias: params.row.storeAlias } })
                  }
                }
              },
              "编辑"),
              h("Button",
                {
                  props: { type: params.row.status === 1 ? "warning" : "success" },
                  on: {
                    click: () => {
                      if (params.row.status === 1) {
                        this.$Modal.confirm({
                          content: "确定禁用吗?",
                          onOk: async () => await this.disableStore(params.row.storeAlias)
                        })
                      } else {
                        this.reduction(params.row.storeAlias)
                      }
                    },
                  },
                },
                params.row.status === 1 ? "禁用" : "解禁",
              ),
            ]),
        },
      ],
      showArticeList: false
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.changePage()
    },
    async createdAtRange() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.changeQueryForm()
      },
    }
  },
  async mounted() {
    this.process(await queryBusinesstype())
    await this.reload()
  },
  methods: {
    process(cate) {
      if (cate.children && !cate.isLeaf) {
        cate.children = cate.children.map(item => this.process(item))
      }
      cate.expand = true
      cate.title = cate.name
      this.businessTypeInList.push({ name: cate.name, alias: cate.alias })
    },
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        let status = null
        if (q.status > -1) {
          status = q.status
        }
        let type = null
        if (q.type > -1) {
          type = q.type
        }
        const { items, count } = await queryStore({
          page: this.page,
          pageSize: 20,
          keyword: q.keyword,
          businessTypeAlias: q.businessTypeAlias,
          startTime: startTime,
          district: q.district,
          endTime: endTime
        })
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async disableStore(alias) {
      try {
        await disableStore(alias)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async reduction(alias) {
      try {
        await enableStore(alias)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    openUrl(url) {
      window.open(url)
    },
    haddleNew() {
      this.$router.push({ name: "StorePublishPage", query: { } })
    },
    async changePage() {
      if (this.$route.query.page === this.page) {
        return
      }
      const query = Object.assign({}, this.queryForm, { page: this.page })
      this.$router.replace({ name: "StoreQueryPage", query: query })
      await this.reload()
    },
    async changeQueryForm() {
      this.page = 1
      const query = Object.assign({}, this.queryForm, { page: this.page })
      this.$router.replace({ name: "StoreQueryPage", query: query })
      await this.reload()
    },
    showUserArticeList(alias) {
      this.articeAlias = alias
      this.showArticeList = true
    }
  },
}
</script>
