<template>
  <div>
    <Form inline>
      <FormItem>
        <Input v-model="queryForm.title" type="text" placeholder="名称" />
      </FormItem>
      <FormItem>
        <DatePicker v-model="createdAtRange" type="datetimerange" placement="bottom-end" placeholder="创建时间范围"
          style="width: 200px"></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :data="items" :columns="tableColumns" style="width: 100%" :loading="tableLoading" />
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showItemDetail" title="文章详情" @on-ok="onEdit" width="100%">
      <ArticeDetail :alias="currentItem" ref="editor" />
    </Modal>
  </div>
</template>
    
<script>
import Pager from "@/components/Pager.vue"
import { queryArticle, deleteArticle, updateArticle, hideArticle, showArticle } from "@/api"
import ArticeDetail from "@/components/artice/ArticeDetail.vue"
import "@/components/tableUtil"
import { editAndRemove, apiUtil, toHumanDate } from "@/util"

export default {
  name: "UserSelector",
  components: { Pager, ArticeDetail },
  props: {
    storeAlias: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { 
        title: "",
        storeAlias: this.storeAlias
      },
      tableLoading: false,
      tableColumns: [
        { title: "标识", key: "alias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          render: (h, params) => {
            return h(
              "a",
              {
                on: {
                  click: () => {
                    this.showItemDetail = true
                    this.currentItem = params.row.alias
                  },
                },
              },
              [params.row.title],
            )
          },
          fixed: "left",
        },
        {
          title: "作者",
          render(h, p) {
            return h("div", p.row.author)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "状态",
          render: (h, params) => {
            return h("StatusHelper", {
              props: { value: params.row.status, on: 1, off: 0 },
              on: {
                change: async (value) => {
                  try {
                    if (value === 1) {
                      await showArticle(params.row.alias)
                    } else {
                      await hideArticle(params.row.alias)
                    }
                  } catch (e) {
                    this.$Message.error(e.message)
                  }
                },
              },
            })
          },
        },
        {
          title: "操作",
          width: 150,
          render: (h, params) =>
            editAndRemove.call(
              this,
              h,
              params,
              () => {
                this.edit(params.row.alias)
              },
              async () => {
                await this.delete(params.row.alias)
              },
            ),
        },
      ],
      showItemDetail: false,
      currentItem: ""
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const name = q.name === "" ? null : q.name
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryArticle(Object.assign({}, q, {page: this.page}))
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
    edit(alias) {
      this.currentItem = alias
      this.showItemDetail = true
    },
    async delete(alias) {
      try {
        await deleteArticle(alias)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async onEdit() {
      apiUtil.call(
        this,
        async () => {
          const editor = this.$refs.editor
          const medias = editor.uploadedImages.map((item, index) => {
            if (index === 0) {
              return { path: item.path, sortOrder: 100 - index, isPrimary: true }
            } else {
              return { path: item.path, sortOrder: 100 - index }
            }
          })
          const formData = Object.assign({}, editor.form, {"medias": medias})
          await updateArticle(formData)
          this.$Message.success("编辑成功")
          await this.reload()
        },
        () => {
          this.showItemDetail = true
        },
      )
    }
  },
}
</script>
    